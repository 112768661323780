@font-face {
  font-family: "La Luxes Script";
  src: local("La Luxes Script"), local("La-Luxes-Script"),
    url("../fonts/LaLuxesScript.woff2") format("woff2"),
    url("../fonts/LaLuxesScript.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "La Luxes Serif";
  src: local("La Luxes Serif"), local("La-Luxes-Serif"),
    url("../fonts/LaLuxesSerif.woff2") format("woff2"),
    url("../fonts/LaLuxesSerif.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
